import React, { lazy } from 'react';

const SubcontractCN = lazy(() =>
  import('../SubconListing/SubconListing').then(module => ({
    default: module.SubconListing,
  })),
);
// const SubcontractCN = lazy(() =>
//   import('../../AccountLedgerModule/AccountLedgerTransaction').then(module => ({
//     default: module.AccountLedgerTransaction,
//   })),
// );

const SubcontractLedgerDetail = lazy(() =>
  import('../SubconDetail/SubcontractCnDetail').then(module => ({
    default: module.SubcontractCnDetail,
  })),
);

const SubcontractLedgerForm = lazy(() =>
  import('../SubconForm/SubcontractCnForm').then(module => ({
    default: module.SubcontractCnForm,
  })),
);

const SubcontractLedgerMobileView = lazy(() =>
  import(
    '../../AccountLedgerModule/AccountLedgerMobileView/CreditNotePDF/CreditNotePDFMobile'
  ).then(module => ({
    default: module.CredMobile,
  })),
);

const SubcontractAllocation = lazy(() =>
  import('../SubconListing/SubconAllocation').then(module => ({
    default: module.SubconAllocation,
  })),
);

const GLItemForm = lazy(() =>
  import('../SubconForm/GLItemForm').then(module => ({
    default: module.GLItemForm,
  })),
);

export const SubcontractCNRoutes = [
  {
    props: {
      exact: true,
      path: `/subcontract/submenu/:subcontractID/account-status/credit-notes`,
    },
    component: (
      <SubcontractCN accountType="subcontract" transactionType="Credit Notes" />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontract/submenu/:ID/account-status/credit-notes/detail/:transactionID`,
    },
    component: (
      <SubcontractLedgerDetail
        accountType="subcontract"
        transactionType="Credit Notes"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/credit-notes`,
    },
    component: (
      <SubcontractCN
        accountType="subcontractor"
        transactionType="Credit Notes"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/credit-notes`,
    },
    component: (
      <SubcontractCN
        accountType="subcontractor"
        transactionType="Credit Notes"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/credit-notes/detail/:transactionID`,
    },
    component: (
      <SubcontractLedgerDetail
        accountType="subcontractor"
        transactionType="Credit Notes"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/credit-notes/allocation/:transactionID`,
    },
    component: <SubcontractAllocation transactionType="Credit Notes" />,
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/credit-notes/add`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Credit Notes"
        formMode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/credit-notes/:formMode/item/:itemMode`,
    },
    component: <GLItemForm transactionType="credit-notes" />,
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/credit-notes/:formMode/:transactionID/item/:itemMode`,
    },
    component: <GLItemForm transactionType="credit-notes" />,
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/credit-notes/:formMode/item/:itemMode/:itemID`,
    },
    component: <GLItemForm transactionType="credit-notes" />,
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/credit-notes/:formMode/:transactionID/item/:itemMode/:itemID`,
    },
    component: <GLItemForm transactionType="credit-notes" />,
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/credit-notes/draft/:transactionID`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Credit Notes"
        formMode="draft"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/credit-notes/approve-reject/:transactionID`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Credit Notes"
        formMode="approve-reject"
      />
    ),
  },
  //workdesk
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/credit-notes/approve-reject/:refTable/workdesk/:transactionID`,
      // path: `/subcontractor-account/account-transaction/:ID/credit-notes/approve-reject/:refTable/workdesk/:transactionID`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Credit Notes"
        formMode="approve-reject"
        type="workdesk"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/credit-notes/resubmit/:transactionID`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Credit Notes"
        formMode="resubmit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/credit-notes/edit/:transactionID`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Credit Notes"
        formMode="edit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/credit-notes/mobile-view',
    },
    component: <SubcontractLedgerMobileView />,
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/credit-notes/:transactionID/mobile-view',
    },
    component: <SubcontractLedgerMobileView />,
  },
];
