import React, { lazy } from 'react';

const SubcontractDN = lazy(() =>
  import('../SubconListing/SubconListing').then(module => ({
    default: module.SubconListing,
  })),
);
// const SubcontractDN = lazy(() =>
//   import('../../AccountLedgerModule/AccountLedgerTransaction').then(module => ({
//     default: module.AccountLedgerTransaction,
//   })),
// );

const SubcontractLedgerDetail = lazy(() =>
  import('../SubconDetail/SubcontractDnDetail').then(module => ({
    default: module.SubcontractDnDetail,
  })),
);

const SubcontractLedgerForm = lazy(() =>
  import('../SubconForm/SubcontractDnForm').then(module => ({
    default: module.SubcontractDnForm,
  })),
);

const SubcontractLedgerMobileView = lazy(() =>
  import(
    '../../AccountLedgerModule/AccountLedgerMobileView/DebitNotePDF/DebitNotePDFMobile'
  ).then(module => ({
    default: module.DebMobile,
  })),
);

const GLItemForm = lazy(() =>
  import('../SubconForm/GLItemForm').then(module => ({
    default: module.GLItemForm,
  })),
);

export const SubcontractDNRoutes = [
  {
    props: {
      exact: true,
      path: `/subcontract/submenu/:subcontractID/account-status/debit-notes`,
    },
    component: (
      <SubcontractDN accountType="subcontract" transactionType="Debit Notes" />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontract/submenu/:ID/account-status/debit-notes/detail/:transactionID`,
    },
    component: (
      <SubcontractLedgerDetail
        accountType="subcontract"
        transactionType="Debit Notes"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/debit-notes`,
    },
    component: (
      <SubcontractDN
        accountType="subcontractor"
        transactionType="Debit Notes"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/debit-notes`,
    },
    component: (
      <SubcontractDN
        accountType="subcontractor"
        transactionType="Debit Notes"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/debit-notes/detail/:transactionID`,
    },
    component: (
      <SubcontractLedgerDetail
        accountType="subcontractor"
        transactionType="Debit Notes"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/debit-notes/add`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Debit Notes"
        formMode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/debit-notes/draft/:transactionID`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Debit Notes"
        formMode="draft"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/debit-notes/approve-reject/:transactionID`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Debit Notes"
        formMode="approve-reject"
      />
    ),
  },
  //workdesk
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/debit-notes/approve-reject/:refTable/workdesk/:transactionID`,
      // path: `/subcontractor-account/account-transaction/:ID/debit-notes/approve-reject/:refTable/workdesk/:transactionID`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Debit Notes"
        formMode="approve-reject"
        type="workdesk"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/debit-notes/resubmit/:transactionID`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Debit Notes"
        formMode="resubmit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/debit-notes/edit/:transactionID`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Debit Notes"
        formMode="edit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/debit-notes/:formMode/item/:itemMode`,
    },
    component: <GLItemForm transactionType="debit-notes" />,
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/debit-notes/:formMode/:transactionID/item/:itemMode`,
    },
    component: <GLItemForm transactionType="debit-notes" />,
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/debit-notes/:formMode/item/:itemMode/:itemID`,
    },
    component: <GLItemForm transactionType="debit-notes" />,
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/debit-notes/:formMode/:transactionID/item/:itemMode/:itemID`,
    },
    component: <GLItemForm transactionType="debit-notes" />,
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/debit-notes/mobile-view',
    },
    component: <SubcontractLedgerMobileView />,
  },
  {
    props: {
      exact: true,
      path:
        '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/debit-notes/:transactionID/mobile-view',
    },
    component: <SubcontractLedgerMobileView />,
  },
];
