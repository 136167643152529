import React, { lazy } from 'react';

const SubcontractMisc = lazy(() =>
  import('../SubconListing/SubconListing').then(module => ({
    default: module.SubconListing,
  })),
);
// const SubcontractMisc = lazy(() =>
//   import('../../AccountLedgerModule/AccountLedgerTransaction').then(module => ({
//     default: module.AccountLedgerTransaction,
//   })),
// );

const SubcontractLedgerDetail = lazy(() =>
  import('../SubconDetail/SubcontractMiscInvDetail').then(module => ({
    default: module.SubcontractMiscInvDetail,
  })),
);

const SubcontractLedgerForm = lazy(() =>
  import('../SubconForm/SubcontractMiscInvForm').then(module => ({
    default: module.SubcontractMiscInvForm,
  })),
);

const SubcontractLedgerMobileView = lazy(() =>
  import(
    '../../AccountLedgerModule/AccountLedgerMobileView/MiscellaneousInvoicePDF/SubMiscInvPDFMobile'
  ).then(module => ({
    default: module.MiscInvoicePDFMobile,
  })),
);

const SubcontractLedgerPreview = lazy(() =>
  import(
    '../SubconAccountPDF/SubconPreviewDetailPDF/SubconMiscInvPreviewDetailPDF'
  ).then(module => ({
    default: module.SubconMiscInvPreviewDetailPDF,
  })),
);

const GLItemForm = lazy(() =>
  import('../SubconForm/GLItemForm').then(module => ({
    default: module.GLItemForm,
  })),
);

export const SubcontractMiscInvRoutes = [
  {
    props: {
      exact: true,
      path: `/subcontract/submenu/:subcontractID/account-status/misc-invoices`,
    },
    component: (
      <SubcontractMisc
        accountType="subcontract"
        transactionType="Miscellaneous Invoices"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontract/submenu/:ID/account-status/misc-invoices/detail/:transactionID`,
    },
    component: (
      <SubcontractLedgerDetail
        accountType="subcontract"
        transactionType="Miscellaneous Invoices"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/misc-invoices`,
    },
    component: (
      <SubcontractMisc
        accountType="subcontractor"
        transactionType="Miscellaneous Invoices"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/misc-invoices`,
    },
    component: (
      <SubcontractMisc
        accountType="subcontractor"
        transactionType="Miscellaneous Invoices"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/misc-invoices/:formMode/item/:itemMode`,
    },
    component: <GLItemForm transactionType="misc-invoices" />,
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/misc-invoices/:formMode/:transactionID/item/:itemMode`,
    },
    component: <GLItemForm transactionType="misc-invoices" />,
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/misc-invoices/:formMode/item/:itemMode/:itemID`,
    },
    component: <GLItemForm transactionType="misc-invoices" />,
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/misc-invoices/:formMode/:transactionID/item/:itemMode/:itemID`,
    },
    component: <GLItemForm transactionType="misc-invoices" />,
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/misc-invoices/detail/:transactionID`,
    },
    component: (
      <SubcontractLedgerDetail
        accountType="subcontractor"
        transactionType="Miscellaneous Invoices"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/misc-invoices/add`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Miscellaneous Invoices"
        formMode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/misc-invoices/draft/:transactionID`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Miscellaneous Invoices"
        formMode="draft"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/misc-invoices/approve-reject/:transactionID`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Miscellaneous Invoices"
        formMode="approve-reject"
      />
    ),
  },
  // workdesk
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/misc-invoices/approve-reject/:refTable/workdesk/:transactionID`,
      // path: `/subcontractor-account/account-transaction/:ID/misc-invoices/approve-reject/:refTable/workdesk/:transactionID`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Miscellaneous Invoices"
        formMode="approve-reject"
        type="workdesk"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/misc-invoices/resubmit/:transactionID`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Miscellaneous Invoices"
        formMode="resubmit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/misc-invoices/edit/:transactionID`,
    },
    component: (
      <SubcontractLedgerForm
        accountType="subcontractor"
        transactionType="Miscellaneous Invoices"
        formMode="edit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/misc-invoices/mobile-view',
    },
    component: <SubcontractLedgerMobileView />,
  },
  {
    props: {
      exact: true,
      path: '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/misc-invoices/:transactionID/mobile-view',
    },
    component: <SubcontractLedgerMobileView />,
  },
  {
    props: {
      exact: true,
      path: '/subcontractor-account/account-transaction/:subcontractorID/:subcontractID/misc-invoices/:transactionID/preview',
    },
    component: (
      <SubcontractLedgerPreview
        accountType="subcontractor"
        transactionType="Miscellaneous Invoices"
      />
    ),
    //SubcontractLedgerPreview was used to view the SubconMiscInvoices PDF
  },
];
