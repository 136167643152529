import React, { lazy } from 'react';

const ContractClientDN = lazy(() =>
  import('../ClientContractListing/ClientContractListing').then(module => ({
    default: module.ClientContractListing,
  })),
);
// const ContractClientDN = lazy(() =>
//   import('../../AccountLedgerModule/AccountLedgerTransaction').then(module => ({
//     default: module.AccountLedgerTransaction,
//   })),
// );

const ContractLedgerForm = lazy(() =>
  import('../ClientContractForm/ClientDnForm').then(module => ({
    default: module.ClientDnForm,
  })),
);
const ContractLedgerDetail = lazy(() =>
  import('../ClientContractDetail/ClientDnDetail').then(module => ({
    default: module.ClientDnDetail,
  })),
);
const ContractLedgerAllocation = lazy(() =>
  import('../ClientContractListing/ClientContractAllocation').then(module => ({
    default: module.ClientContractAllocation,
  })),
);

const GLItemForm = lazy(() =>
  import('containers/SubconAccountModule/SubconForm/GLItemForm').then(
    module => ({
      default: module.GLItemForm,
    }),
  ),
);

export const ContractClientDNRoutes = [
  {
    props: {
      exact: true,
      path: `/contract/submenu/:contractID/account-status/client-debit-notes`,
    },
    component: (
      <ContractClientDN accountType="contract" transactionType="Client DN" />
    ),
  },
  {
    props: {
      exact: true,
      path: `/contract/submenu/:ID/account-status/client-debit-notes/detail/:transactionID`,
    },
    component: (
      <ContractLedgerDetail
        accountType="contract"
        transactionType="Client DN"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/client-debit-notes`,
    },
    component: (
      <ContractClientDN accountType="client" transactionType="Client DN" />
    ),
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/:contractID/client-debit-notes`,
    },
    component: (
      <ContractClientDN accountType="client" transactionType="Client DN" />
    ),
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/:contractID/client-debit-notes/allocation/:transactionID`,
    },
    component: <ContractLedgerAllocation transactionType="Client DN" />,
  },
  {
    props: {
      exact: true,
      path: '/client-account/submenu/:ID/:contractID/client-debit-notes/add',
    },
    component: (
      <ContractLedgerForm
        transactionType="Client DN"
        accountType="client"
        formMode="add"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/:contractID/client-debit-notes/:formMode/item/:itemMode`,
    },
    component: <GLItemForm transactionType="client-debit-notes" />,
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/:contractID/client-debit-notes/:formMode/:transactionID/item/:itemMode`,
    },
    component: <GLItemForm transactionType="client-debit-notes" />,
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/:contractID/client-debit-notes/:formMode/item/:itemMode/:itemID`,
    },
    component: <GLItemForm transactionType="client-debit-notes" />,
  },
  {
    props: {
      exact: true,
      path: `/client-account/submenu/:ID/:contractID/client-debit-notes/:formMode/:transactionID/item/:itemMode/:itemID`,
    },
    component: <GLItemForm transactionType="client-debit-notes" />,
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/client-debit-notes/edit/:transactionID',
    },
    component: (
      <ContractLedgerForm
        transactionType="Client DN"
        accountType="client"
        formMode="edit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/client-debit-notes/approve-reject/:transactionID',
    },
    component: (
      <ContractLedgerForm
        transactionType="Client DN"
        accountType="client"
        formMode="approve-reject"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/client-debit-notes/approve-reject/:refTable/workdesk/:transactionID',
    },
    component: (
      <ContractLedgerForm
        transactionType="Client DN"
        accountType="client"
        formMode="approve-reject"
        type="workdesk"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/client-debit-notes/resubmit/:transactionID',
    },
    component: (
      <ContractLedgerForm
        transactionType="Client DN"
        accountType="client"
        formMode="resubmit"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/client-account/submenu/:ID/:contractID/client-debit-notes/detail/:transactionID',
    },
    component: (
      <ContractLedgerDetail transactionType="Client DN" accountType="client" />
    ),
  },
];
